<template>
  <van-popup
    class="coupon-popup"
    :value="show"
    @click-overlay="$emit('update:show', false)"
  >
    <div class="toast-wrap">
      <img class="toast-img" src="~image/index/toast.png" alt="" />
      <p class="toast-text">七星彩功能模块正在开发中<br />敬请期待</p>
      <div class="toast-btn" @click="$emit('update:show', false)">好的</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.coupon-popup {
  border-radius: 16px;
  overflow: initial;
}

.toast {
  &-wrap {
    width: 542px;
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
  }

  &-img {
    width: 100%;
    height: 120px;
    border-radius: 16px 16px 0 0;
  }

  &-text {
    padding: 30px;
    width: 100%;
    line-height: 48px;
    text-align: center;
    font-size: 36px;
    text-align: center;
    color: #232323;
  }

  &-btn {
    @include flex-center-center;
    @include hairlines(top, #d1d1d1);
    width: 100%;
    height: 98px;
    border-radius: 0 0 16px 16px;
    font-size: 34px;
    color: #040404;
  }
}
</style>
