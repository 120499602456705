<template>
  <van-popup class="coupon-popup" :value="show">
    <div class="coupon-wrap" v-if="couponData.empty">
      <img
        class="coupon-img"
        src="~image/index/coupon.png"
        @click="handleCouponImgClick"
      />
      <app-icon
        class="coupon-close"
        name="close"
        size="54"
        @click.native="$emit('update:show', false)"
      ></app-icon>
    </div>
    <div class="success-wrap" v-if="!couponData.empty && couponData.message">
      <img class="success-img" src="~image/index/success.png" alt="" />
      <p class="success-text">您已领取9.99元新用户补贴<br />可在个人中心查看</p>
      <div class="success-btn" @click="$emit('update:show', false)">好的</div>
    </div>
  </van-popup>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      couponData: state => state.user.couponData,
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    ...mapMutations({
      updateCouponData: 'user/setCouponData',
      updateUserMoney: 'user/updateUserMoney'
    }),
    handleLinkClick() {
      this.$router.push('/login');
    },

    handleCouponImgClick() {
      if (!this.userInfo.id) return this.$router.push('/login');
      this.$http('/user/receiveCoupon').then(res => {
        this.updateUserMoney(Number(res) || 0);
        this.updateCouponData({ couponEmpty: false, msgNewUser: '领取成功' });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.coupon-popup {
  overflow: initial;
}

.coupon {
  &-popup {
    background: transparent;
  }

  &-wrap {
    @include align-center;
    flex-direction: column;
  }

  &-img {
    width: 544px;
    height: 603px;
  }

  &-close {
    margin-top: 20px;
  }
}

.success {
  &-wrap {
    width: 542px;
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
  }

  &-img {
    width: 100%;
    height: 120px;
    border-radius: 16px 16px 0 0;
  }

  &-text {
    padding: 30px;
    width: 100%;
    line-height: 48px;
    text-align: center;
    font-size: 36px;
    text-align: center;
    color: #232323;
  }

  &-btn {
    @include flex-center-center;
    @include hairlines(top, #d1d1d1);
    width: 100%;
    height: 98px;
    border-radius: 0 0 16px 16px;
    font-size: 34px;
    color: #040404;
  }
}
</style>
