<template>
  <div class="module-video">
    <video class="js-video video" controls></video>
    <div class="a1">
      <div class="wrap"></div>
      <div class="content"></div>
    </div>
    <div class="a2">
      <div class="wrap"></div>
      <div class="content"></div>
    </div>
  </div>
</template>

<script>
import mpegts from 'mpegts.js';

export default {
  mounted() {
    if (mpegts.getFeatureList().mseLivePlayback) {
      let videoElement = document.querySelector('.js-video');
      let player = mpegts.createPlayer({
        type: 'flv', // could also be mpegts, m2ts, flv
        isLive: true,
        url: 'https://livevideortmp.lottery.gov.cn/live/stream.flv'
        // url: 'https://v.sporttery.cn/KJSP/2021.12.28.f4v.mp4'
      });
      player.attachMediaElement(videoElement);
      player.load();
    }
  }
};
</script>

<style lang="scss" scoped>
.module-video {
  background: gold;
  height: 100vh;
}

.video {
  width: 100%;
}

.a1 {
  margin: 30px auto 0;
  width: 500px;
  height: 300px;

  .content {
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0 0;
    background: radial-gradient(50px at -20px 100%, transparent 50px, #fff 20px)
        left,
      radial-gradient(
          50px at calc(100% + 15px) 100%,
          transparent 50px,
          #fff 20px
        )
        right;
    background-size: 51% 100%; /* width height 一般和 background-repeat: no-repeat; 搭配使用 */
    background-repeat: no-repeat;
  }
}

.a2 {
  margin: 0 auto;
  width: 500px;
  height: 300px;

  .content {
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 0 0 20px 20px;
    background: radial-gradient(50px at -20px 0px, transparent 50px, #fff 20px)
        left,
      radial-gradient(
          50px at calc(100% + 15px) 0px,
          transparent 50px,
          #fff 20px
        )
        right;
    background-size: 51% 100%; /* width height 一般和 background-repeat: no-repeat; 搭配使用 */
    background-repeat: no-repeat;
  }
}

.wrap {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
  filter: blur(50px);
}
</style>
