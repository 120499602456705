<template>
  <div class="distribution" v-if="distributionData">
    <slot name="title"></slot>
    <div class="distribution-datas">
      <div class="distribution-data">
        <div class="data-name">
          <app-icon
            class="data-icon"
            name="distribution-fenxiaoshang"
            size="68"
          ></app-icon>
          <p>分销商(个)</p>
        </div>
        <p class="data-text">{{ distributionData.distributionNumber }}</p>
      </div>
      <div class="distribution-data">
        <div class="data-name">
          <app-icon
            class="data-icon"
            name="distribution-order"
            size="68"
          ></app-icon>
          <p>订单(笔)</p>
        </div>
        <p class="data-text">{{ distributionData.orderTotal }}</p>
      </div>
      <div class="distribution-data">
        <div class="data-name">
          <app-icon
            class="data-icon"
            name="distribution-lirun"
            size="68"
          ></app-icon>
          <p>累计利润(元)</p>
        </div>
        <p class="data-text">{{ distributionData.profit }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({ distributionData: state => state.user.distributionData })
  }
};
</script>

<style lang="scss" scoped>
.distribution {
  padding: 30px 0 0;
  background: #fff;

  &-datas {
    padding: 0 30px;
    margin-top: 30px;
  }

  &-data {
    @include flex-between-center;
    width: 100%;
    height: 120px;

    .data {
      &-icon {
        margin-right: 10px;
      }

      &-name {
        @include align-center;
        font-size: 34px;
        color: #a3a3a3;
      }

      &-text {
        font-size: 36px;
        font-weight: 500;
        color: #4a7cf6;
      }
    }
  }
}
</style>
