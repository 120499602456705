<template>
  <router-link
    :to="
      linkDisabled
        ? ''
        : `/lottery/master?masterId=${masterData.id}&forecastType=10`
    "
    class="module-master-item"
    v-if="masterData"
  >
    <app-icon
      class="master-face"
      name="touxiang"
      v-show="!masterData.face"
    ></app-icon>
    <img
      class="master-face"
      v-show="masterData.face"
      :src="masterData.face"
      alt="奖师头像"
    />
    <div class="master-container">
      <div class="master-info">
        <div class="info-item">
          <p class="master-name">{{ masterData.name }}</p>
          <p class="info-text">
            <span class="info-text">(10中{{ masterData.count }})</span>
          </p>
        </div>
        <p class="info-item_rate">回报率：{{ masterData.rateOfReturn }}%</p>
      </div>
      <p class="master-continuous">
        <span>连中</span>
        <span class="continuous-value">{{ masterData.continuous }}</span>
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    masterData: {
      type: Object,
      default() {
        return null;
      }
    },
    linkDisabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.module-master-item {
  @include align-center;
  padding: 0 30px;
  width: 100%;
  height: 148px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 10px 24px 0px rgba(141, 141, 141, 0.16);
}

.master {
  &-face {
    flex: 0 0 auto;
    margin-right: 30px;
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }

  &-container {
    @include flex-between-center;
    flex-grow: 1;
    overflow: hidden;
    font-size: 32px;
  }

  &-info {
    .info {
      &-item {
        display: flex;
        margin-bottom: 6px;

        &_rate {
          font-size: 28px;
          font-weight: 500;
          color: #4a7cf6;
        }
      }

      &-text {
        color: #5f5f5f;

        &_red {
          color: #e12c00;
        }
      }
    }
  }

  &-name {
    @include text-ellipsis;
    margin-right: 12px;
    max-width: 180px;
    font-size: 36px;
    font-weight: 500;
  }

  &-continuous {
    @include align-center;
    font-weight: 500;
    color: #f12f00;

    .continuous-value {
      margin-left: 14px;
      font-size: 70px;
    }
  }
}
</style>
