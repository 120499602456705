<template>
  <div class="module-result">
    <div class="result-filter">
      <p class="item_active">排列五</p>
      <p class="item" @click="$emit('filterClick')">七星彩</p>
    </div>
    <div class="result-wrap">
      <div class="result-container">
        <p class="result-title">
          <span>排列五</span>
          <span class="result-issue">第{{ resultData.issue }}期</span>
        </p>
        <div class="result-values">
          <p class="value" v-for="(val, idx) in resultData.value" :key="idx">
            <span class="value-text">
              {{ val }}
            </span>
          </p>
        </div>
      </div>
      <router-link to="/lottery/history" :class="`result-info`">
        <div class="info-item">
          <p class="info-label">上期命中人数</p>
          <p class="info-value">
            <span class="info-value_bold">{{ resultData.total }}</span
            >位
          </p>
        </div>
        <div class="info-item">
          <p class="info-label">本月命中次数</p>
          <p class="info-value">
            <span class="info-value_bold">{{ resultData.monthTotal }}</span
            >次
          </p>
        </div>
        <div class="info-item">
          <p class="info-label">最高连续命中</p>
          <p class="info-value">
            <span class="info-value_bold">{{ resultData.continuous }}</span
            >期
          </p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      upadteLoading: false,
      curTime: 0,
      nextResultTime: 0
    };
  },
  computed: {
    ...mapState({
      resultData: state => state.result.resultData
    })
  },
  methods: {
    ...mapActions({ updateResultData: 'result/updateResultData' }),

    getResultDataReady() {
      let { curTime, nextResultTime } = this;
      let interval = nextResultTime - curTime;
      if (interval <= 0 || interval > 1800000) return;
      if (this.upadteTimer) clearTimeout(this.upadteTimer);
      this.curTime += 3000;
      this.upadteTimer = setTimeout(() => {
        return interval < 1200000
          ? this.getResultData()
          : this.getResultDataReady();
      }, 3000);
    },

    async getResultData() {
      if (this.upadteLoading) return;
      this.upadteLoading = true;
      let res = await this.updateResultData();
      if (!this.curTime) {
        this.curTime = res.time * 1000;
        this.nextResultTime = this.$dayjs(
          this.$dayjs(this.curTime).format('YYYY-M-D')
        )
          .add(20, 'h')
          .add(50, 'm')
          .valueOf();
      }
      // 如果已经执行clearResultDataUpdate，则不再轮询
      if (!this.upadteLoading) return;
      this.upadteLoading = false;
      this.getResultDataReady();
    },

    handleStickyChange(isFixed) {
      this.isFixed = isFixed;
    },

    clearResultDataUpdate() {
      if (this.upadteTimer) clearTimeout(this.upadteTimer);
      this.upadteLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.module-result {
  padding: 0 30px;
}

.result {
  &-filter {
    display: flex;
    margin-bottom: 20px;

    .item {
      margin-right: 50px;
      font-size: 40px;
      color: #a9a9a9;

      &_active {
        @extend .item;
        font-weight: 600;
        color: #ff1028;
      }
    }
  }

  &-wrap {
    border-radius: 16px;
    box-shadow: 0px 4px 22px 0px rgba(115, 40, 45, 0.43);
  }

  &-info {
    @include align-center;
    z-index: 2;
    margin: 0 auto;
    width: 100%;
    height: 128px;
    border-radius: 0 0 16px 16px;
    background: #fff;

    .info {
      &-item {
        @include justify-center;
        flex-direction: column;
        padding-left: 40px;
        width: 33.333%;
        height: 100%;
        font-size: 22px;
        color: #0e0e0e;

        &:not(:last-of-type)::after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: calc(50% - 30px);
          width: 2px;
          height: 70px;
          background: #e6e6e6;
        }
      }

      &-value {
        font-size: 24px;
        font-weight: 500;
        color: #0e0e0e;

        &_bold {
          font-size: 38px;
        }
      }

      &-label {
        margin-bottom: 6px;
        color: #747474;
      }
    }
  }

  &-container {
    padding: 28px 0 0;
    width: 100%;
    height: 210px;
    border-radius: 16px 16px 0 0;
    background: url(~image/index/result-bg.png) 0 0 / cover no-repeat;
  }

  &-title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 38px;
    font-weight: 500;
    background: linear-gradient(180deg, #ffffff 0%, #ffeec4 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-issue {
    margin-left: 20px;
  }

  &-values {
    @include flex-center-center;

    .value {
      @include flex-center-center;
      margin-right: 53px;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: linear-gradient(360deg, #ffcfb4 0%, #ffffff 100%);
      &:last-of-type {
        margin-right: 0;
      }

      &-text {
        font-size: 42px;
        font-weight: 500;
        background: linear-gradient(180deg, #ff5e39 0%, #c91d00 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &-link {
    @include align-end;
    justify-content: center;
    margin: -134px auto 0;
    padding-bottom: 10px;
    width: 640px;
    height: 196px;
    border-radius: 106px;
    background: #ffede5;
    transition: margin 0.18s linear;

    &_fixed {
      @extend .result-link;
      display: none;
    }

    .link-text {
      @include flex-center-center;
      font-size: 28px;
      font-weight: 500;
      color: #e45338;
    }
  }
}
</style>
