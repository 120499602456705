<template>
  <van-popup
    class="help"
    v-model="show"
    round
    safe-area-inset-bottom
    position="bottom"
  >
    <div class="help-title">
      <app-icon name="wenhao" size="56" class="help-icon"></app-icon>
      <span>预估佣金计算规则</span>
    </div>
    <div class="help-content">
      <app-icon name="wenhao" size="56" class="help-icon"></app-icon>
      <p class="help-text">
        1.卖家可以随时在佣金范围内调整主推商品佣金比率。<br />
        2.卖家可以随时在佣金范围内调整店铺各类目的佣金比率。<br />
        3.买家从淘宝客推广链接进入起15天内产生的所有成交均为有效,淘宝客都可得到由卖家支付。<br />
        4.佣金根据支付宝实际成交金额(不包含邮费)乘以佣金比率计算。<br />
      </p>
    </div>
    <div class="close-btn">我知道了</div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      show: false
    };
  }
};
</script>

<style lang="scss" scoped>
.help {
  padding: 38px 30px 40px;

  &-icon {
    margin-right: 7px;
  }

  &-title {
    @include align-center;
    margin-bottom: 36px;
    font-size: 32px;
  }

  &-content {
    display: flex;
  }

  &-text {
    line-height: 44px;
    font-size: 26px;
    color: #808080;
  }
}

.close-btn {
  @include flex-center-center;
  margin: 103px auto 0;
  width: 598px;
  height: 88px;
  border-radius: 44px;
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(180deg, #fea06a 0%, #fd4a26 100%);
}
</style>
