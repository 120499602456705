<template>
  <div class="promote" v-if="promoteData">
    <slot name="title"></slot>
    <div class="promote-datas">
      <div class="promote-data">
        <div class="data-name">
          <app-icon class="data-icon" name="promote-order" size="68"></app-icon>
          <p>订单(笔)</p>
        </div>
        <p class="data-text">{{ promoteData.orderTotal }}</p>
      </div>
      <div class="promote-data">
        <div class="data-name">
          <app-icon
            class="data-icon"
            name="promote-xiaoshoue"
            size="68"
          ></app-icon>
          <p>销售额(元)</p>
        </div>
        <p class="data-text">{{ promoteData.saleVolume }}</p>
      </div>
      <div class="promote-data">
        <div class="data-name">
          <app-icon
            class="data-icon"
            name="promote-yongjin"
            size="68"
          ></app-icon>
          <p>累计佣金(元)</p>
        </div>
        <p class="data-text">{{ promoteData.commission }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({ promoteData: state => state.user.promoteData })
  }
};
</script>

<style lang="scss" scoped>
.promote {
  padding: 30px 0 0;
  background: #fff;

  &-datas {
    margin-top: 30px;
    padding: 0 30px;
  }

  &-data {
    @include flex-between-center;
    width: 100%;
    height: 120px;

    .data {
      &-icon {
        margin-right: 10px;
      }

      &-name {
        @include align-center;
        font-size: 34px;
        color: #a3a3a3;
      }

      &-text {
        font-size: 36px;
        font-weight: 500;
        color: #4a7cf6;
      }
    }
  }
}
</style>
