<template>
  <div class="module-video">
    <video class="js-video video" controls></video>
  </div>
</template>

<script>
import mpegts from 'mpegts.js';

export default {
  mounted() {
    if (mpegts.getFeatureList().mseLivePlayback) {
      let videoElement = document.querySelector('.js-video');
      let player = mpegts.createPlayer({
        type: 'flv', // could also be mpegts, m2ts, flv
        isLive: true,
        url: 'https://livevideortmp.lottery.gov.cn/live/stream.flv'
        // url: 'https://v.sporttery.cn/KJSP/2021.12.28.f4v.mp4'
      });
      player.attachMediaElement(videoElement);
      player.load();
    }
  }
};
</script>

<style>
.video {
  width: 100%;
}
</style>
