<template>
  <div
    :class="`module-masterItem${
      masterData && masterData.forecastType !== 10 ? '_notType10' : ''
    }`"
    v-if="masterData"
  >
    <p class="master-ranking">
      <img
        v-if="masterData.rankingImg"
        class="ranking-img"
        :src="masterData.rankingImg"
      />
      <span v-else>{{ masterData.ranking }}</span>
    </p>
    <div class="master-container">
      <div class="master-info">
        <app-icon
          class="master-face"
          name="touxiang"
          size="88"
          v-show="!masterData.face"
        ></app-icon>
        <img
          class="master-face"
          v-show="masterData.face"
          :src="masterData.face"
          alt="奖师头像"
        />
        <div>
          <div class="master-name">
            <p class="name_text">{{ masterData.name }}</p>
            <p class="master-new" v-if="!masterData.forecast">最新预测</p>
          </div>
          <template v-if="masterData.count && masterData.continuous">
            <div class="master-count">
              <p class="count-item">10中{{ masterData.count }}</p>
              <p class="count-item">连中{{ masterData.continuous }}</p>
            </div>
          </template>
          <p class="master-rateOfReturn" v-if="masterData.forecastType === 10">
            回报率: {{ masterData.rateOfReturn }}%
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterData: {
      type: Object,
      default() {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterItem {
  position: relative;
  display: flex;
  padding: 30px 20px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);

  &_notType10 {
    @extend .module-masterItem;
    @include align-center;

    .master-ranking {
      margin-top: 0;
    }
  }
}

.master {
  &-ranking {
    @include flex-center-center;
    margin: 6px 22px 0 0;
    width: 64px;
    height: 56px;
    font-size: 52px;
    color: #c6c6c6;

    .ranking {
      &-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &-container {
    flex: 1;
  }

  &-face {
    flex: 0 0 auto;
    margin: 0 30px 0 0;
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }

  &-name {
    @include align-center;
    margin-bottom: 8px;

    .name_text {
      @include text-ellipsis;
      margin-right: 8px;
      max-width: 180px;
      font-size: 36px;
      font-weight: 500;
      color: #333;
    }
  }

  &-new {
    @include flex-center-center;
    width: 108px;
    height: 32px;
    border-radius: 16px;
    font-size: 22px;
    color: #fff;
    text-shadow: 1px 1px 1px #ab6800;
    background: linear-gradient(360deg, #ff9c0b 0%, #ffc868 100%);
  }

  &-info {
    display: flex;
  }

  &-count {
    display: flex;
    margin-bottom: 8px;

    .count {
      &-item {
        @include align-center;
        margin-right: 20px;
        font-size: 30px;
        color: #828282;

        &:last-of-type {
          margin-right: 0;
          color: #ff1028;
        }
      }

      &-text {
        color: #e12c00;
      }
    }
  }

  &-rateOfReturn {
    font-size: 28px;
    color: #4a7cf6;
  }
}
</style>
