<template>
  <div class="module-userInfo">
    <div class="user-info_wrap">
      <img v-if="userInfo.face" :src="userInfo.face" class="user-face" />
      <app-icon class="user-face" name="touxiang" size="120" v-else></app-icon>
      <div class="user-info">
        <template v-if="userInfo.id">
          <p class="user-name">
            <span>{{ userInfo.name }}</span>
            <router-link to="/user/servicePage">
              <img
                class="name-icon"
                v-if="superVipData"
                src="~image/user/user/superVip.png"
                alt=""
            /></router-link>
          </p>
          <p class="user-phone">{{ userInfo.phone }}</p>
        </template>
        <router-link v-else class="user-login" to="/login">请登录</router-link>
      </div>
      <router-link
        v-if="userInfo.id"
        class="user-service"
        to="/user/serviceOrderList"
        >我的服务</router-link
      >
    </div>
    <div class="user-datas">
      <div class="user-data">
        <p class="user-data_name">余额</p>
        <p class="user-data_text" v-if="userInfo.money">
          {{ userInfo.money }}
        </p>
        <p v-else class="user-data_text">-</p>
      </div>
      <!-- <div class="user-data">
        <p class="user-data_name">积分</p>
        <p class="user-data_text" v-if="userInfo.integral">
          {{ userInfo.integral }}
        </p>
        <p v-else class="user-data_none">-</p>
      </div> -->
      <router-link
        class="user-data"
        :to="`${$route.path}/orderList?userId=${userInfo.id}`"
      >
        <p class="user-data_name">我的锦囊</p>
        <p class="user-data_text" v-if="userInfo.orderNum">
          {{ userInfo.orderNum }}<span class="small">期</span>
        </p>
        <p v-else class="user-data_text">-</p>
      </router-link>
      <router-link
        to="/distribution/withdrawal"
        class="user-data"
        v-if="userInfo.isSalesman"
      >
        <p class="user-data_name">可提现</p>
        <p class="user-data_text" v-if="userInfo.price">
          {{ userInfo.price }}
        </p>
        <p v-else class="user-data_text">-</p>
      </router-link>
    </div>
    <div :class="`user-vip${userInfo.serviceTime ? '' : '_overdue'}`">
      <template v-if="userInfo.serviceOrderNum">
        <div class="vip-content">
          <img class="vip-icon" src="~image/user/user/vip.png" alt="" />
          <p class="vip-info">{{ serviceTimeText }}</p>
        </div>
        <router-link class="vip-link" to="/user/serviceList"
          >立即续费</router-link
        >
      </template>
      <router-link v-else class="vip-banner" to="user/serviceList"
        ><img src="~image/user/user/ad1.png"
      /></router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      superVipData: state => state.user.superVipData
    }),

    serviceTimeText() {
      let { userInfo } = this;
      return userInfo.serviceTime
        ? `您的锦囊会员${
            userInfo.serviceTime === '0小时'
              ? '即将到期'
              : `还有 ${userInfo.serviceTime} 到期`
          }`
        : `您的锦囊会员已到期`;
    }
  }
};
</script>

<style lang="scss" scoped>
.module-userInfo {
  margin: 0 auto;
  padding: 40px 30px 30px;
  background: linear-gradient(180deg, #30210c 0%, #0e0700 100%);
  color: #fff;
}

.user {
  &-info {
    &_wrap {
      @include align-center;
      width: 100%;
    }
  }

  &-face {
    margin-right: 30px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  &-name {
    @include align-center;
    margin-bottom: 12px;
    font-size: 42px;
    font-weight: 500;

    .name-icon {
      margin-left: 8px;
      width: 41px;
      height: 54px;
    }
  }

  &-phone {
    font-size: 30px;
  }

  &-login {
    width: 400px;
    font-size: 32px;
    font-weight: 500;
  }

  &-service {
    @include flex-center-center;
    position: absolute;
    top: 0;
    right: -4px;
    width: 150px;
    height: 58px;
    border-radius: 24px;
    font-size: 28px;
    color: #4b0000;
    background: linear-gradient(180deg, #fff0ae 0%, #f9c431 100%);
  }

  &-vip {
    @include flex-between-center;
    padding: 0 26px 0 30px;
    width: 100%;
    height: 94px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    font-size: 28px;
    color: #171717;
    background: linear-gradient(270deg, #ff9c0b 0%, #ffc868 100%);

    &_overdue {
      @extend .user-vip;
      background: linear-gradient(270deg, #9d9d9d 0%, #d6d6d6 100%);
    }

    .vip {
      &-content {
        @include align-center;
      }

      &-icon {
        margin-right: 11px;
        width: 39px;
        height: 32px;
      }

      &-link {
        @include flex-center-center;
        width: 140px;
        height: 44px;
        border-radius: 29px;
        font-size: 28px;
        font-weight: 500;
        color: #511c00;
        background: linear-gradient(180deg, #fff9de 0%, #ffce2a 100%);
      }

      &-banner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-datas {
    @include align-center;
    padding: 30px 0 20px;
    width: 100%;
  }

  &-data {
    @include align-center;
    flex-direction: column;
    flex: 1;

    &_name {
      margin-bottom: 16px;
      color: #959595;
    }

    &_text {
      font-size: 38px;
      font-weight: 500;
      color: #fff;

      .small {
        font-size: 24px;
      }
    }
  }
}
</style>
