<template>
  <div class="module-masterList">
    <slot name="title"></slot>
    <div class="master-list">
      <router-link
        class="master-item"
        :to="`/lottery/master?masterId=${item.id}&forecastType=${item.type}`"
        v-for="item in list"
        :key="item.id"
      >
        <div class="item-new" v-if="item.new"></div>
        <img class="item-face" :src="item.face" />
        <p class="item-name">{{ item.name }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-masterList {
  padding: 40px 0 0;
  background: #fff;
}

.master {
  &-list {
    @include align-center;
    width: 100%;
  }

  &-item {
    @include flex-center-center;
    flex-direction: column;
    flex: 0 0 auto;
    margin: 40px 5px 0;
    width: 140px;

    .item {
      &-new {
        @include flex-center-center;
        z-index: 1;
        position: absolute;
        top: -5px;
        right: 25px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        color: #fff;
        background: #ff082b;
      }

      &-face {
        margin-bottom: 8px;
        width: 88px;
        height: 88px;
        border-radius: 50%;
      }

      &-name {
        @include text-ellipsis;
        width: 100%;
        font-size: 28px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
</style>
