<template>
  <!-- 个人中心 -->
  <div class="user">
    <module-user-info></module-user-info>
    <div class="user-container">
      <template
        v-if="
          userInfo.id &&
          (masterList.length || userInfo.isSalesman || distributionApplyData)
        "
      >
        <master-list :list="masterList" v-if="masterList.length">
          <template v-slot:title>
            <p class="title">
              <span class="title-text">我的关注</span>
              <router-link class="title-link" to="/user/followerList"
                >查看更多</router-link
              >
            </p>
          </template>
        </master-list>
        <template v-if="userInfo.isSalesman">
          <module-promote-data>
            <template v-slot:title>
              <p class="title">
                <span class="title-text">我的直推数据</span>
                <router-link class="title-link" to="/distribution/promoteData"
                  >查看详细数据</router-link
                >
              </p>
            </template>
          </module-promote-data>
          <module-distribution-data>
            <template v-slot:title>
              <p class="title">
                <span class="title-text">分销数据</span>
                <router-link
                  class="title-link"
                  to="/distribution/distributionData"
                  >查看详细数据</router-link
                >
              </p>
            </template>
          </module-distribution-data>
          <router-link to="/distribution/share" class="ad-link_2"></router-link>
          <module-help></module-help>
        </template>
        <div v-else-if="distributionApplyData" class="distribution-status">
          <p v-if="!distributionApplyData.status">分销商申请审核中</p>
          <template v-else-if="distributionApplyData.status === 2">
            <p>
              分销商申请未能通过<br />拒绝原因：{{
                distributionApplyData.error
              }}
            </p>
            <router-link to="/user/register" class="distribution-status_link"
              >点我重新申请</router-link
            >
          </template>
        </div>
      </template>
      <p class="user-empty" v-else>暂无数据</p>
      <p class="tip">
        访问本站表示您同意：<br />本站提供的资料和数据仅供参考，请您在使用前仔细甄别并慎重对待，使用本站数据产生的任何后果，本站不承担任何责任。
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moduleUserInfo from './components/moduleUserInfo.vue';
import moduleDistributionData from './components/moduleDistributionData.vue';
import modulePromoteData from './components/modulePromoteData.vue';
import moduleHelp from './components/moduleHelp.vue';
import masterList from './components/masteList.vue';

export default {
  components: {
    moduleUserInfo,
    moduleDistributionData,
    modulePromoteData,
    moduleHelp,
    masterList
  },
  data() {
    return {
      initializing: false,
      masterList: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      distributionApplyData: state => state.user.distributionApplyData
    })
  },
  methods: {
    ...mapActions('user', [
      'getUserInfo',
      'getPromoteData',
      'getDistributionData'
    ]),

    ...mapMutations('user', [
      'setUserInfo',
      'setPromoteData',
      'setDistributionData',
      'setDistributionApplyData',
      'setSuperVipData'
    ]),

    initialization(showLoading) {
      this.initializing = true;
      if (showLoading) this.$toast.loading({});
      Promise.all([
        this.getUserInfo({ loading: showLoading, dialog: false }),
        this.getMasterList({ loading: showLoading, dialog: false })
      ])
        .catch(err => {
          let errData = JSON.parse(err.message);
          if (!showLoading) {
            this.$dialog.alert({
              message: `页面加载失败，请联系管理员！\n错误信息：${
                errData.message || '未知错误'
              }`
            });
          }
        })
        .finally(() => {
          if (showLoading) this.$toast.clear();
          this.initializing = false;
        });
    },

    getUserInfo(config = {}) {
      return this.$http('user/center', {}, config).then(res => {
        this.setUserInfo({ ...res.user, parentCode: res.parentCode });
        if (res.push || res.push.length === 0) {
          this.setPromoteData(res.push);
        }
        if (res.superior || res.superior.length === 0) {
          this.setDistributionData(res.superior);
        }
        this.setDistributionApplyData(res.superiorApply || null);
        this.setSuperVipData(res.user.vipData || null);
      });
    },

    getMasterList(config) {
      return this.$http(
        'followers/index',
        { page: 1, pageSize: 5 },
        config
      ).then(res => {
        this.masterList = res.data.map(e => {
          return {
            id: e.followingId,
            name: e.nickName,
            face: e.avatarUrl,
            type: e.type,
            new: e.predict
          };
        });
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name && from.name !== 'user') {
      to.meta.isBack = true;
    }
    next();
  },
  activated() {
    // this.$dialog.alert({ title: '链接', message: window.location.href });
    if (this.initializing || !localStorage.getItem('token')) return;
    let showLoading = !this.$route.meta.isBack;
    this.initialization(showLoading);
  }
};
</script>

<style lang="scss" scoped>
.user {
  min-height: calc(100vh - $tabBarHeight);

  &-container {
    margin-top: -30px;
    padding: 0 0 166px;
    width: 100%;
    min-height: calc(100vh - 501px);
    border-radius: 32px 32px 0 0;
    overflow: hidden;
    background: #fff;
  }

  &-empty {
    position: absolute;
    top: 50%;
    width: 100%;
    font-size: 32px;
    text-align: center;
    color: #8e8e8e;
    transform: translateY(-50%);
  }
}

.title {
  @include flex-between-center;
  padding: 0 30px;

  .title {
    &-text {
      font-size: 38px;
      font-weight: 500;
      color: #000;
    }

    &-link {
      @include align-center;
      @include arrow(15px, right, #a3a3a3);

      font-size: 28px;
      color: #a3a3a3;

      &::after {
        margin-left: 7px;
      }
    }
  }
}

.ad {
  &-link {
    width: 100%;
    height: 180px;

    &_1 {
      @extend .ad-link;
      margin: 28px auto 0;
      height: 142px;
      background: url(~image/user/user/ad1.png) center bottom/contain no-repeat;
    }

    &_2 {
      @extend .ad-link;
      background: #fff url(~image/user/user/ad2.png) center/694px 150px
        no-repeat;
    }
  }
}

.module-masterList {
  margin-bottom: 10px;
  &::after {
    content: '';
    display: block;
    margin-top: 40px;
    width: 100%;
    height: 10px;
    background: #f7f7f7;
  }
}

.promote {
  margin-bottom: 10px;

  &::after {
    content: '';
    display: block;
    margin-top: 40px;
    width: 100%;
    height: 10px;
    background: #f7f7f7;
  }
}

.distribution {
  &-status {
    @include flex-center-center;
    flex-direction: column;
    z-index: 2;
    width: 100%;
    min-height: 200px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    background: #fff;
    color: #959595;

    &_link {
      margin-top: 16px;
      font-size: 28px;
      color: #ff825e;
    }
  }
}

.tip {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 30px 30px;
  text-align: center;
  color: #ccc;
}
</style>
